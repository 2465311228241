import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";
import {SetAttributeLocalizationCommand} from "./setAttributeLocalization.command";
import {v4 as uuidv4} from "uuid";

export class AddConstantBuildingAttribute extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(state: RemoteConstantsModel): boolean {
        if (this.id === '') {
            return false;
        }

        if (state.buildingAttributes === undefined) {
            state.buildingAttributes = [];
        }

        const uid = uuidv4();
        new SetAttributeLocalizationCommand(uid, this.id).execute(state);

        state.buildingAttributes.push({
            uid: uid,
            id: this.id,
            active: true,
            values: {},
            entities: {}
        });
        return true;
    }
}