import {useIntegrator} from "../../integrator.context";
import {AddTileCommand} from "../../commands/addTile.command";
import LocalizationInput from "../../elements/localizationInput.element";
import {RemoveTileCommand} from "../../commands/removeTile.command";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import {UpdateTileCommand} from "../../commands/updateTile.command";
import BooleanInputLabel from "../../elements/booleanInputLabel";
import EntityLabel from "../../elements/entityLabel";
import {TileData} from "../../models/remoteConstants.model";
import EntityDragLabel from "../../elements/entityDragLabel";
import {DropTargetMonitor, useDrop} from "react-dnd";
import Vector2Input from "../../elements/vector2.input";
import BooleanInput from "../../elements/booleanInput.element";
import {ChangeConstantsCommand} from "../../commands/changeConstants.command";
import {moveObjectDown, moveObjectUp} from "../../utils/array.utils";
import IconButton from "../../elements/IconButton";
import SelectableLabel from "../../elements/selectable.label";
import ImagePackElement from "../../elements/imagePack.element";

function TilesEditorView() {
    const {state, executeCommand} = useIntegrator();

    return (
        <div className={'layout horizontal'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout vertical gap'} style={{gap: 10, flexGrow: 1}}>
                <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', gap: 10, height: 0}}>
                    {state.tiles !== undefined && state.tiles.map((tile, index) => {
                        return <Tile key={index} tile={tile}/>
                    })}
                </div>
                <button onClick={() => executeCommand(new AddTileCommand())}>Add New Tile</button>
            </div>
            <div className={'layout vertical gap'} style={{gap: 10}}>
                <div className={'layout vertical gap box flex-grow'}>
                    <p className={'text header'}>{`Resources`}</p>
                    <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', width: '25vw'}}>
                        {state.resources !== undefined && state.resources !== null && state.resources.map((resource, i) => {
                            return <EntityDragLabel key={i} id={resource.id} type={'resource'}/>
                        })}
                    </div>
                </div>
                <div className={'layout vertical gap box'} style={{height: '30vh'}}>
                    <p className={'text header'}>{`Buildings`}</p>
                    <div className={'layout vertical gap'} style={{flexGrow: 1, overflowY: 'auto', width: '25vw'}}>
                        {state.buildings !== undefined && state.buildings !== null && state.buildings.map((building, i) => {
                            return <EntityDragLabel key={i} id={building.id} type={'building'}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

function Tile(props: { tile: TileData }) {
    const {state, executeCommand} = useIntegrator();

    function getSupportingTileOptions(): string[] {
        const otherTiles = state.tiles.filter(t => t.id !== props.tile.id).map(t => t.id);
        otherTiles.unshift('');
        return otherTiles;
    }

    function checkCanDrop(monitor: DropTargetMonitor<any, void>): boolean {
        if (!monitor.isOver({shallow: true})) {
            return false;
        }

        if (props.tile === undefined) {
            return false;
        }

        const type = monitor.getItemType();
        const item = monitor.getItem();
        switch (type) {
            case 'resource':
                return !props.tile.resources?.includes(item.id);
            case 'building':
                return !props.tile.buildings?.includes(item.id);
            default:
                return false;
        }
    }

    const [{canDrop}, drop] = useDrop({
        accept: ['resource', 'building'],
        drop: (item: any, monitor) => {
            if (props.tile === undefined || !checkCanDrop(monitor)) {
                return;
            }

            switch (monitor.getItemType()) {
                case 'resource':
                    props.tile.resources ??= [];
                    props.tile.resources.push(item.id);
                    break;
                case 'building':
                    props.tile.buildings ??= [];
                    props.tile.buildings.push(item.id);
                    break;
                default:
                    return;
            }

            executeCommand(new UpdateTileCommand(props.tile));
        },
        collect: (monitor) => {
            return {
                canDrop: checkCanDrop(monitor)
            };
        },
    });

    return (
        <div ref={drop} className={'box'} style={{
            opacity: props.tile.active ? 1 : 0.5,
            border: `solid 1px ${canDrop ? 'var(--tint-color)' : 'var(--overlay-color)'}`}}
        >
            <div className={'layout horizontal gap'} style={{gap: 20}}>
                <ImagePackElement id={props.tile.id} size={80}/>
                <div className={'layout vertical gap'}>
                    <div className={'layout horizontal gap'}>
                        <LocalizationInput id={props.tile.id} style={{width: 100}}/>
                        <BooleanInput value={props.tile.active} onChange={(value) => {
                            props.tile.active = value;
                            executeCommand(new UpdateTileCommand(props.tile));
                        }}/>
                        <IconButton icon={'trash'} onClick={() => {
                            executeCommand(new RemoveTileCommand(props.tile.id));
                        }}/>
                        <IconButton icon={'arrow-down'} onClick={() => {
                            executeCommand(new ChangeConstantsCommand(data => {
                                data.tiles = moveObjectDown(data.tiles, props.tile);
                            }));
                        }}/>
                        <IconButton icon={'arrow-up'} onClick={() => {
                            executeCommand(new ChangeConstantsCommand(data => {
                                data.tiles = moveObjectUp(data.tiles, props.tile);
                            }));
                        }}/>
                    </div>
                </div>
                <div className={'layout vertical gap'} style={{gap: 20}}>
                    <p className={'text header'}>World Generation</p>
                    <div className={'layout vertical gap'}>
                        <NumberInputLabel title={'Rarity'} value={props.tile.rarity}
                                          style={{flexGrow: 'unset'}}
                                          onChange={(value) => {
                                              props.tile.rarity = value;
                                              executeCommand(new UpdateTileCommand(props.tile));
                                          }}/>
                        <Vector2Input title={'Elevation Noise'} value={props.tile.elevationNoise}
                                      onChange={(value) => {
                                          props.tile.elevationNoise = value;
                                          executeCommand(new UpdateTileCommand(props.tile));
                                      }}/>
                        <Vector2Input title={'Temperature Noise'} value={props.tile.temperatureNoise}
                                      onChange={(value) => {
                                          props.tile.temperatureNoise = value;
                                          executeCommand(new UpdateTileCommand(props.tile));
                                      }}/>
                    </div>
                    <p className={'text header'}>Statistics</p>
                    <div className={'layout vertical gap'}>
                        <NumberInputLabel title={'Additional Vision'} value={props.tile.additionalVisionRange}
                                          style={{flexGrow: 'unset'}}
                                          onChange={(value) => {
                                              props.tile.additionalVisionRange = value;
                                              executeCommand(new UpdateTileCommand(props.tile));
                                          }}/>
                        <NumberInputLabel title={'Movement Price'} value={props.tile.movementPrice}
                                          style={{flexGrow: 'unset'}}
                                          onChange={(value) => {
                                              props.tile.movementPrice = value;
                                              executeCommand(new UpdateTileCommand(props.tile));
                                          }}/>
                        <SelectableLabel name={'Supporting Tile'} value={props.tile.supportingTile}
                                    options={getSupportingTileOptions()} onChange={value => {
                            props.tile.supportingTile = value;
                            executeCommand(new UpdateTileCommand(props.tile));
                        }}/>
                        <BooleanInputLabel title={'Can Move'} value={props.tile.canMove} onChange={value => {
                            props.tile.canMove = value;
                            executeCommand(new UpdateTileCommand(props.tile));
                        }}/>
                        <BooleanInputLabel title={'Can Settle'} value={props.tile.canSettle} onChange={value => {
                            props.tile.canSettle = value;
                            executeCommand(new UpdateTileCommand(props.tile));
                        }}/>
                        <BooleanInputLabel title={'Can Sail'} value={props.tile.canSail} onChange={value => {
                            props.tile.canSail = value;
                            executeCommand(new UpdateTileCommand(props.tile));
                        }}/>
                        <BooleanInputLabel title={'Can Hide'} value={props.tile.canHide} onChange={value => {
                            props.tile.canHide = value;
                            executeCommand(new UpdateTileCommand(props.tile));
                        }}/>
                    </div>
                </div>
                <div className={'layout vertical gap flex-grow'}>
                    <div className={'layout vertical gap'}
                         style={{
                             borderRadius: '5px',
                             flexGrow: 1
                         }}>
                        <p className={'text header'}>Resources, Buildings</p>
                        {props.tile.resources && props.tile.resources.map((resource, i) => {
                            return <EntityLabel key={i} id={resource} onDelete={() => {
                                props.tile.resources = props.tile.resources.filter(r => r !== resource);
                                executeCommand(new UpdateTileCommand(props.tile));
                            }}/>
                        })}
                        {props.tile.buildings && props.tile.buildings.map((building, i) => {
                            return <EntityLabel key={i} id={building} onDelete={() => {
                                props.tile.buildings = props.tile.buildings.filter(r => r !== building);
                                executeCommand(new UpdateTileCommand(props.tile));
                            }}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TilesEditorView;