import {apiBindings} from "../../utils/constants";
import {useIntegrator} from "../../integrator.context";
import {RemoveFileArchiveCommand} from "../../commands/removeFileArchive.command";
import SoundAsset from "../../elements/soundAsset";
import IconButton from "../../elements/IconButton";

function ArchiveView() {
    const {state, executeCommand} = useIntegrator();

    function getFileType(fileName: string): string {
        return fileName.split('.')[1];
    }

    return (
        <div className={'layout vertical'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout horizontal gap'} style={{flexWrap: 'wrap', alignContent: 'flex-start', gap: 10, flexGrow: 1, overflow: 'auto', height: 0}}>
                {state.fileArchive !== undefined && state.fileArchive.map((fileName, index) => {
                    const url = `${apiBindings.images}${fileName}`;
                    const type = getFileType(fileName);
                    return (
                        <div key={index} style={{
                            position: 'relative',
                            width: 200, height: 200,
                            backgroundColor: 'var(--box-color)',
                            borderRadius: 'var(--border-radius)'
                        }}>
                            {type === 'png' && <img alt={fileName} style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%'
                            }}
                                  src={url}
                            />}
                            <div style={{
                                position: 'absolute',
                                borderRadius: 'var(--border-radius)',
                                width: '100%',
                                height: '100%'
                            }}>
                                <div className={'layout horizontal gap'} style={{padding: 5}}>
                                    <IconButton icon={'fa-solid fa-trash'} onClick={(e) => {
                                        e.stopPropagation();
                                        executeCommand(new RemoveFileArchiveCommand(fileName));
                                    }}/>
                                    <IconButton icon={'fa-solid fa-download'} onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(url);
                                    }}/>
                                </div>
                            </div>
                            {type === 'wav' && <div style={{
                                position: 'absolute',
                                margin: 50,
                            }}>
                                <SoundAsset id={fileName} size={100} upload={false}/>
                            </div>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ArchiveView;