import {useApp} from "../../app.context";
import {useEffect, useState} from "react";
import StringInputLabel from "../../elements/stringInputLabel.element";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import {useIntegrator} from "../../integrator.context";
import {MetadataValueMethod, MetadataValueCommand, MetadataValueTypes} from "../../commands/metadataValue.command";
import SelectableLabel from "../../elements/selectable.label";

function AddMetadataValueView() {
    const {state, executeCommand} = useIntegrator();
    const {metadataValue, setMetadataValue} = useApp();
    const [key, setKey] = useState<string>('');
    const [type, setType] = useState<MetadataValueTypes>(MetadataValueTypes.Number);
    const [defaultNumber, setDefaultNumber] = useState<number>(0);
    const [defaultEntity, setDefaultEntity] = useState<string>('');

    const curType = type.toLowerCase();

    const entityOptions = state.units.map(u => u.id)
        .concat(state.buildings.map(b => b.id))
        .concat(state.tiles.map(t => t.id))
        .concat(state.resources.map(r => r.id));

    useEffect(() => {
        switch (curType) {
            case MetadataValueTypes.Number:
            case MetadataValueTypes.Entity:
                setKey('');
                break;
            case MetadataValueTypes.EntityValue:
                setKey(entityOptions[0]);
                break;
        }
    }, [type, curType, entityOptions]);

    function addValue() {
        if (metadataValue === undefined || key.length === 0) {
            return;
        }

        const method = MetadataValueMethod.AddOrUpdate;
        const value = curType !== MetadataValueTypes.Entity ? defaultNumber : defaultEntity;

        executeCommand(new MetadataValueCommand(metadataValue.collection, method, curType as MetadataValueTypes, metadataValue.metadata.uid, key, value))
        setMetadataValue(undefined);
    }

    return (
        <div className={'layout vertical overlay'} onClick={() => {
            setMetadataValue(undefined);
        }}>
            <div className={'layout vertical gap box'} style={{padding: 10, gap: 10, width: 400}}
                 onClick={e=> e.stopPropagation()}
            >
                <p className={'text header'}>New Attribute Value</p>
                <SelectableLabel name={'Type'} value={type} options={Object.keys(MetadataValueTypes)} onChange={v => setType(v as MetadataValueTypes)}/>
                {curType !== MetadataValueTypes.EntityValue && <StringInputLabel title={'Key'} placeholder={'Enter key...'} value={key} onChange={setKey}/>}
                {curType === MetadataValueTypes.EntityValue && <SelectableLabel name={'Key'} value={key} options={entityOptions}
                             onChange={v => setKey(v)}
                />}
                {curType !== MetadataValueTypes.Entity && <NumberInputLabel title={'Default Value'} value={defaultNumber} onChange={setDefaultNumber}/>}
                {curType === MetadataValueTypes.Entity && <SelectableLabel name={'Default Value'} value={defaultEntity} options={entityOptions}
                                                                    onChange={v => setDefaultEntity(v)}
                />}
                <button onClick={addValue}>Add</button>
            </div>
        </div>
    )
}

export default AddMetadataValueView;