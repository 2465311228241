import {CSSProperties} from "react";

interface IconButtonProps {
    icon: string;
    onClick(event: React.MouseEvent<HTMLButtonElement>): void;
    style?: CSSProperties | undefined;
}

function IconButton(props: IconButtonProps) {
    return (
        <button onClick={e => props.onClick(e)} style={props.style}>
            <span className={`fa-solid fa-${props.icon}`}/>
        </button>
    )
}

export default IconButton;