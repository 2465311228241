import {Metadata} from "../models/remoteConstants.model";
import {useApp} from "../app.context";
import {useIntegrator} from "../integrator.context";
import {
    MetadataCollection,
    MetadataValueMethod,
    MetadataValueCommand,
    MetadataValueTypes
} from "../commands/metadataValue.command";
import NumberInputLabel from "./numberInputLabel.element";
import AssetImage from "./assetImage.element";
import StringInput from "./stringInput.element";
import {MetadataIdCommand} from "../commands/metadataId.command";
import SelectableLabel from "./selectable.label";
import BooleanInput from "./booleanInput.element";
import {ChangeMetadataCommand} from "../commands/changeMetadata.command";

interface MetadataProps {
    metadata: Metadata;
    collection: MetadataCollection;
    onDelete(): void;
}

function MetadataElement(props: MetadataProps) {
    const {executeCommand} = useIntegrator();
    const {setNotification, setMetadataValue, getEntityOptions, getLocalizationValue} = useApp();

    const valueKeys = Object.keys(props.metadata.values);
    const entityKeys = Object.keys(props.metadata.entities);

    return (
        <div className={'layout vertical center stretch gap box'} style={{padding: '10px', borderRadius: 'var(--border-radius)', backgroundColor: 'var(--box-color)', opacity: props.metadata.active ? '1' : '0.5'}}>
            <div className={'layout horizontal center stretch'} style={{width: '100%'}}>
                <div className={'layout horizontal gap center'} style={{gap: 10}}>
                    <AssetImage id={props.metadata.uid} size={40}/>
                    <div className={'layout vertical'}>
                        <div className={'layout horizontal gap'}>
                            <BooleanInput value={props.metadata.active} onChange={(value) => {
                                props.metadata.active = value;
                                executeCommand(new ChangeMetadataCommand(props.metadata.uid, props.collection, metadata => {
                                    metadata.active = value;
                                }));
                            }}/>
                            <p style={{width: 160}} onClick={() => setNotification({
                                title: props.metadata.uid,
                                message: `${props.metadata.uid}.description`,
                                edit: true
                            })}>
                                {getLocalizationValue(props.metadata.uid)}
                            </p>
                        </div>
                        <div className={'layout horizontal center gap'}>
                            <p style={{color: 'var(--overlay-color)'}}>ID:</p>
                            <StringInput value={props.metadata.id}
                                         style={{fontSize: 15, padding: 3, color: 'var(--overlay-color)'}}
                                         onChange={v => {
                                            executeCommand(new MetadataIdCommand(props.collection, props.metadata.uid, v))
                                         }}/>
                        </div>
                    </div>
                </div>
                <div className={'layout horizontal gap'}>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        setMetadataValue({
                            metadata: props.metadata,
                            collection: props.collection
                        });
                    }}>
                        <span className={'fa-solid fa-add'}/>
                    </button>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        props.onDelete();
                    }}>
                        <span className={'fa-solid fa-trash'}/>
                    </button>
                </div>
            </div>
            {valueKeys.length > 0 && <>
                {valueKeys.map((key, index) => {
                    const value = props.metadata.values[key];
                    return (
                        <div key={index} className={'layout horizontal gap stretch'} style={{width: '100%'}}>
                            <NumberInputLabel title={key} value={value} onChange={(v) => {
                                executeCommand(new MetadataValueCommand(props.collection, MetadataValueMethod.AddOrUpdate, MetadataValueTypes.Number, props.metadata.uid, key, v));
                            }}/>
                            <button onClick={() => {
                                executeCommand(new MetadataValueCommand(props.collection, MetadataValueMethod.Remove, MetadataValueTypes.Number, props.metadata.uid, key));
                            }}>
                                <span className={'fa-solid fa-trash'}/>
                            </button>
                        </div>
                    )
                })}
            </>}
            {entityKeys.length > 0 && <>
                {entityKeys.map((key, index) => {
                    const value = props.metadata.entities[key];
                    return (
                        <div key={index} className={'layout horizontal gap'} style={{width: '100%'}}>
                            <SelectableLabel name={key} value={value} options={getEntityOptions()} onChange={(v) => {
                                executeCommand(new MetadataValueCommand(props.collection, MetadataValueMethod.AddOrUpdate, MetadataValueTypes.Entity, props.metadata.uid, key, v));
                            }} style={{width: '100%'}}/>
                            <button onClick={() => {
                                executeCommand(new MetadataValueCommand(props.collection, MetadataValueMethod.Remove, MetadataValueTypes.Entity, props.metadata.uid, key));
                            }}>
                                <span className={'fa-solid fa-trash'}/>
                            </button>
                        </div>
                    )
                })}
            </>}
        </div>
    )
}

export default MetadataElement;