function WelcomeView() {
    return (
        <div className={'layout vertical flex-grow'} style={{gap: 10, height: 0, overflowY: 'auto'}}>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Introduction</p>
                <p>This website manages configurations for our isometric strategy game, Rift.</p>
                <p>It allows us, and our valued testers, to experiment and iterate with new rules with the goal of
                    arriving at a deep and well-balanced game as a finished product.</p>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Workflow</p>
                <p>Most of the workflow of this site is built around a simple drag-and drop function.
                    Want to lock a particular unit behind a tech? Simply drag it from a list of all the units into the
                    relevant tech. Want to assign a particular attribute (a special buff or ability) to a particular
                    unit?
                    Simply drag it from a list of all the attributes to the unit you have in mind.
                    The relevant list of assignable assets will always appear to the right of what it can be assigned
                    to, and you can always unassign something by dragging it back onto the list.
                </p>
                <br/>
                <p>
                    Some UI elements are carried through all the tabs. For example, the up and down arrows you’ll see
                    next to many of the assets are purely there to reorder them visually on the site, and have no
                    impact on the game. The trash can icons are to delete something altogether.
                    You can redo or undo actions using the icons to the right of the “Archive” tab if you make any
                    mistakes.
                </p>
                <br/>
                <p>Each tab on this website has an informational pop up (the “i” icon that should display on the main
                    toolbar when you click into it), which explains that particular tab in a little more detail.
                </p>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>How To Play The Game</p>
                <p>You can either play the latest version of the game here:</p>
                <div className={'layout horizontal gap'}>
                    <button onClick={() => window.open('https://rift.xp3games.com')}>Play Rift</button>
                </div>
                <br/><p>Or experiment with your own rulesets by creating your own branch like so:</p>
                <br/>
                <p className={'text header'}>Create your own Branch</p>
                <p>Click on the <b>+</b> button next to the dropdown to open the branches window.</p>
                <p>Enter a unique name for your branch and press <b>Add</b>.</p>
                <div className={'layout horizontal gap'}>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_create_branch_1.png'}/>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_create_branch_2.png'}/>
                </div>
                <br/>
                <p className={'text header'}>Select your Branch In-Game</p>
                <p>Click on the dropdown next to the <b>Export</b> button to view all branches.
                    Select the one you wish to change to.
                </p>
                <p>During the game, click on the <b>Debug</b> button (marked red).
                    Then click on the dropdown to select the branch.
                </p>
                <div className={'layout horizontal gap'}>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_select_branch.png'}/>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_select_branch_client_1.png'}/>
                    <img alt={'img'} className={'preview-img'} src={'./assets/preview_select_branch_client_2.png'}/>
                </div>
            </div>
            <div className={'layout vertical gap box'}>
                <p className={'text header'}>Upload your Changes</p>
                <p>All changes need to be exported in order to test them on the game client.</p>
                <p>On the top-right corner of the screen, press the <b>Export</b> button to upload your changes to the
                    selected branch.</p>
                <img alt={'img'} className={'preview-img'} src={'./assets/preview_export.png'}/>
            </div>
        </div>
    )
}

export default WelcomeView;