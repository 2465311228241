import Selectable, {SelectableProps} from "./selectable.element";
import {Tooltip} from "@mui/material";

interface SelectableLabelProps<T> extends SelectableProps<T> {
    name: string;
    tooltip?: string;
}

function SelectableLabel<T extends string | number>(props: SelectableLabelProps<T>) {
    return (
        <div className={'layout horizontal center stretch'} style={props.style}>
            <div className={'layout horizontal center gap'}>
                <p>{props.name}</p>
                {props.tooltip && <Tooltip title={props.tooltip} arrow disableInteractive>
                    <span className={'fa-light fa-info-circle'} style={{color: 'white'}}/>
                </Tooltip>}
            </div>
            <Selectable value={props.value} options={props.options} onChange={props.onChange}/>
        </div>
    )
}

export default SelectableLabel;