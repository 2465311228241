import {useApp} from "../../app.context";
import {useState} from "react";
import {useIntegrator} from "../../integrator.context";
import {AddSoundCommand} from "../../commands/addSound.command";
import {RemoveSoundCommand} from "../../commands/removeSound.command";
import SoundAsset from "../../elements/soundAsset";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import {UpdateSoundCommand} from "../../commands/updateSound.command";
import {SoundMetadata, SoundTypes} from "../../models/remoteConstants.model";

type SoundDictionary = { [key: string]: SoundMetadata };

function SoundsEditorView() {
    const {state} = useIntegrator();

    function getSoundsByType(type: string): SoundDictionary {
        const dict: SoundDictionary = {};
        for (const id of Object.keys(state.sounds)) {
            const sound = state.sounds[id];
            if (sound.type === type) {
                dict[id] = sound;
            }
        }

        return dict;
    }

    return (
        <div className={'layout horizontal stretch'} style={{gap: 10, flexGrow: 1}}>
            <SoundsCategory title={SoundTypes[0]} sounds={getSoundsByType(SoundTypes[0])}/>
            <SoundsCategory title={SoundTypes[1]} sounds={getSoundsByType(SoundTypes[1])}/>
            <SoundsCategory title={SoundTypes[2]} sounds={getSoundsByType(SoundTypes[2])}/>
        </div>
    )
}

function SoundsCategory(props: {title: string, sounds: SoundDictionary}) {
    const {executeCommand} = useIntegrator();
    const {getLocalizationValue, setNotification} = useApp();
    const [newKey, setNewKey] = useState<string>('');

    function addNewSound() {
        if (newKey === '') {
            setNotification({
                title: 'Invalid Input',
                message: 'Please enter a valid key.'
            })
            return;
        }

        if (props.sounds !== undefined && props.sounds !== null && newKey in props.sounds) {
            setNotification({
                title: 'Key Already Exists',
                message: 'Please enter a new key.'
            })
            return;
        }

        executeCommand(new AddSoundCommand(newKey, props.title));
        setNewKey('');
    }

    return (
        <div className={'layout vertical flex-grow'} style={{gap: 20}}>
            <p className={'text header'}>{props.title}</p>
            <div className={'layout vertical gap'} style={{gap: 10, flexGrow: 1, overflow: 'auto', height: 0}}>
                {props.sounds !== undefined && props.sounds !== null && Object.keys(props.sounds).map((key, index) => {
                    const value = props.sounds[key];
                    return (
                        <div key={index} className={'box'}>
                            <div className={'layout vertical'} style={{gap: 20}}>
                                <div className={'layout horizontal'} style={{gap: 20}}>
                                    <SoundAsset id={key} size={80}/>
                                    <div className={'layout horizontal gap flex-grow'} style={{alignItems: 'flex-start'}}>
                                        <p className={'flex-grow'}>{getLocalizationValue(key)}</p>
                                        <button onClick={() => {
                                            executeCommand(new RemoveSoundCommand(key));
                                        }}>
                                            <span className={'fa-solid fa-trash'}/>
                                        </button>
                                    </div>
                                </div>
                                <div className={'layout vertical gap'}>
                                    <p className={'text header'}>Import Settings</p>
                                    <NumberInputLabel title={'Volume'} tooltip={'Sound volume between 0 and 1.'}
                                                      value={value.volume} onChange={v => {
                                        value.volume = v;
                                        executeCommand(new UpdateSoundCommand(key, value));
                                    }}/>
                                    <NumberInputLabel title={'Channels'} tooltip={'Number of channels per frame.'}
                                                      value={value.channels} onChange={v => {
                                        value.channels = v;
                                        executeCommand(new UpdateSoundCommand(key, value));
                                    }}/>
                                    <NumberInputLabel title={'Frequency'} tooltip={'Sample frequency of clip.'}
                                                      value={value.frequency} onChange={v => {
                                        value.frequency = v;
                                        executeCommand(new UpdateSoundCommand(key, value));
                                    }}/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={'layout vertical gap'}>
                <input placeholder={'Enter key...'} value={newKey} onChange={v => setNewKey(v.target.value)}/>
                <button onClick={() => addNewSound()}>Add New Sound</button>
            </div>
        </div>
    )
}

export default SoundsEditorView;