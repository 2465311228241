import {createContext, useContext, useState} from "react";
import {get, post, postFile} from "./utils/http.client";
import {apiBindings, secretStorageKey} from "./utils/constants";
import {useIntegrator} from "./integrator.context";
import {MetadataCollection} from "./commands/metadataValue.command";
import {Metadata, SoundTypes} from "./models/remoteConstants.model";
import {ChangeConstantsCommand} from "./commands/changeConstants.command";
import {AccessLevel} from "./models/accessLevel.model";

export interface AddMetadataData {
    metadata: Metadata;
    collection: MetadataCollection;
}

export interface NotificationData {
    title: string;
    message: string;
    edit?: boolean;
}

interface AppProviderProps {
    children: React.ReactNode;
}

interface AppContextData {
    secret: string | undefined;
    setSecret(value: string | undefined): void;
    accessLevel: AccessLevel;
    loading: boolean;
    setLoading(value: boolean): void;
    markdownFile: string | undefined;
    setMarkdownFile(value: string | undefined): void;
    metadataValue: AddMetadataData | undefined;
    setMetadataValue(value: AddMetadataData | undefined): void;
    imagePackId: string | undefined;
    setImagePackId(value: string | undefined): void;
    viewSettings: boolean;
    setViewSettings(value: boolean): void;
    viewJson: boolean;
    setViewJson(value: boolean): void;
    viewBranch: boolean;
    setViewBranch(value: boolean): void;
    notification: NotificationData | undefined;
    setNotification(value: NotificationData | undefined): void;
    unit: string | undefined;
    setUnit(value: string | undefined): void;
    validateCachedSecret(): Promise<boolean>;
    validateSecret(secret: string): Promise<boolean>;
    deleteSecret(): void;
    exportRemoteConstants(): Promise<boolean>;
    addBranch(branch: string): Promise<boolean>;
    removeBranch(branch: string): Promise<boolean>;
    uploadFile(id: string, file: File): Promise<boolean>;
    getImageUrl(id: string): string;
    getSoundUrl(id: string): string | undefined;
    getLocalizationValue(key: string): string;
    getEntityOptions(): string[];
}

const AppContext = createContext<AppContextData>({} as AppContextData);

export function AppProvider(props: AppProviderProps) {
    const {branches, branch, state,
        setBranches, setBranch, executeCommand, refreshState} = useIntegrator();

    const [secret, setSecret] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<AccessLevel>(AccessLevel.None);
    const [loading, setLoading] = useState<boolean>(false);
    const [markdownFile, setMarkdownFile] = useState<string | undefined>(undefined);
    const [imagePackId, setImagePackId] = useState<string | undefined>(undefined);
    const [metadataValue, setMetadataValue] = useState<AddMetadataData | undefined>(undefined);
    const [viewSettings, setViewSettings] = useState<boolean>(false);
    const [viewJson, setViewJson] = useState<boolean>(false);
    const [viewBranch, setViewBranch] = useState<boolean>(false);
    const [notification, setNotification] = useState<NotificationData | undefined>(undefined);
    const [unit, setUnit] = useState<string | undefined>(undefined);

    async function validateCachedSecret(): Promise<boolean> {
        const secret = localStorage.getItem(secretStorageKey);
        if (secret === null) {
            return false;
        }

        return await validateSecret(secret);
    }

    async function validateSecret(secret: string): Promise<boolean> {
        const result = await get(`${apiBindings.api}verifySecret`, secret);
        if (!result.success) {
            setNotification({
                title: 'Invalid Secret',
                message: 'Please enter a valid secret and try again.'
            });

            setSecret(undefined);
            setAccessLevel(AccessLevel.None);
            return false;
        }

        localStorage.setItem(secretStorageKey, secret);
        const accessLevel = result.data.accessLevel as number;
        setAccessLevel(accessLevel as AccessLevel);

        await refreshState(branch);
        setSecret(secret);
        return true;
    }

    function deleteSecret() {
        localStorage.removeItem(secretStorageKey);
        setViewSettings(false);
        setSecret(undefined);
    }

    async function exportRemoteConstants(): Promise<boolean> {
        return await exportRemoteConstantsByBranch(branch);
    }

    async function exportRemoteConstantsByBranch(branch: string): Promise<boolean> {
        const clientVersion = process.env.REACT_APP_VERSION ?? '';
        const payload = JSON.stringify(state);
        const response = await post(`${apiBindings.api}setRemoteConstants?branch=${branch}&clientVersion=${clientVersion}`, payload, secret);
        if (response.success) {
            setNotification({
                title: 'Success',
                message: 'You can refresh the game content now.'
            });
        } else {
            setNotification({
                title: 'Failed',
                message: response.data?.message
            });
        }

        return response.success;
    }

    async function addBranch(branch: string): Promise<boolean> {
        setLoading(true);
        const response = await get(`${apiBindings.api}addBranch?branch=${branch}`, secret);
        setLoading(false);

        if (!response.success) {
            setNotification({
                title: 'Failed',
                message: 'Failed to create a new branch.'
            });
            return false;
        }

        const newBranches = [...branches];
        newBranches.push(branch);

        setBranches(newBranches);
        setBranch(branch);
        await exportRemoteConstantsByBranch(branch);
        return true;
    }

    async function removeBranch(removeBranch: string): Promise<boolean> {
        const response = await get(`${apiBindings.api}removeBranch?branch=${removeBranch}`, secret);
        if (!response.success) {
            return false;
        }

        const newBranches = branches.filter(b => b !== removeBranch);
        setBranches(newBranches);

        if (removeBranch === branch) {
            setBranch(newBranches[0]);
        }

        return true;
    }

    async function uploadFile(id: string, file: File): Promise<boolean> {
        const formData = new FormData();
        formData.append("file", file);

        const result = await postFile(`${apiBindings.api}uploadFile?id=${id}`, formData, secret);
        if (!result.success) {
            setNotification({
                title: 'Failed to upload file.',
                message: result.data?.message
            })
            return false;
        }

        const fileName = result.data.fileName as string;
        const fileExtension = fileName.split('.').pop() ?? '';

        executeCommand(new ChangeConstantsCommand(data => {
            switch (fileExtension) {
                case 'png':
                    if (id in data.images) {
                        const curImageData = data.images[id];
                        const oldSoundFileName = curImageData.fileName;
                        curImageData.fileName = fileName;
                        data.fileArchive.push(oldSoundFileName);
                    } else {
                        data.images[id] = {
                            fileName: fileName,
                            pixelMultiplier: 800,
                            offsetX: 0.5,
                            offsetY: 0.5
                        }
                    }
                    break;
                case 'wav':
                    if (id in data.sounds) {
                        const curSoundData = data.sounds[id];
                        const oldSoundFileName = curSoundData.fileName;
                        curSoundData.fileName = fileName;
                        data.fileArchive.push(oldSoundFileName);
                    } else {
                        data.sounds[id] = {
                            fileName: fileName,
                            type: SoundTypes[0],
                            volume: 1,
                            channels: 1,
                            frequency: 44100
                        }
                    }
                    break;
            }
        }));

        return true;
    }

    function getImageUrl(id: string): string {
        if (state.images === undefined || !(id in state.images)) {
            return '/assets/default.png';
        }

        const data = state.images[id];
        let imageUrl = '';

        if (typeof(data) === 'string') {
            imageUrl = `${apiBindings.images}${data}`;
        } else if (data.fileName.length > 0) {
            imageUrl = `${apiBindings.images}${data.fileName}`;
        } else {
            return '/assets/default.png';
        }

        return imageUrl;
    }

    function getSoundUrl(id: string): string | undefined {
        if (id.endsWith('.wav')) {
            return `${apiBindings.images}${id}`;
        }

        if (state.sounds === undefined || !(id in state.sounds)) {
            return undefined;
        }

        const data = state.sounds[id];
        let imageUrl = '';

        if (typeof(data) === 'string') {
            imageUrl = `${apiBindings.images}${data}`;
        } else if (data.fileName.length > 0) {
            imageUrl = `${apiBindings.images}${data.fileName}`;
        } else {
            return undefined;
        }

        return imageUrl;
    }

    function getLocalizationValue(key: string): string {
        if (state?.localization === undefined) {
            return key;
        }

        const language = 'en';
        if (language in state.localization && key in state.localization[language]) {
            return state.localization[language][key];
        }

        return key;
    }

    function getEntityOptions() {
        return state.units.map(u => u.id)
            .concat(state.buildings.map(b => b.id))
            .concat(state.tiles.map(t => t.id))
            .concat(state.resources.map(r => r.id));
    }

    return (
        <AppContext.Provider
            value={{
                secret, setSecret,
                accessLevel,
                loading, setLoading,
                markdownFile, setMarkdownFile,
                imagePackId, setImagePackId,
                metadataValue, setMetadataValue,
                viewSettings, setViewSettings,
                viewJson, setViewJson,
                viewBranch, setViewBranch,
                notification, setNotification,
                unit, setUnit,
                validateCachedSecret,
                validateSecret,
                deleteSecret,
                exportRemoteConstants,
                addBranch,
                removeBranch,
                uploadFile,
                getImageUrl,
                getSoundUrl,
                getLocalizationValue,
                getEntityOptions,
            }}>
            {props.children}
        </AppContext.Provider>
    )
}

export const useApp = () => useContext(AppContext);