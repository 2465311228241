import {Metadata, RemoteConstantsModel} from "../models/remoteConstants.model";
import {CommandBase} from "./commandBase";
import {MetadataCollection} from "./metadataValue.command";

export class ChangeMetadataCommand extends CommandBase {
    uid: string;
    collection: MetadataCollection;
    change: (data: Metadata) => void;

    constructor(uid: string, collection: MetadataCollection, change: (data: Metadata) => void) {
        super();
        this.uid = uid;
        this.collection = collection;
        this.change = change;
    }

    execute(data: RemoteConstantsModel): boolean {
        let metadata: Metadata | undefined = undefined;
        switch (this.collection) {
            case MetadataCollection.Units:
                metadata = this.findMetadata(this.uid, data.unitAttributes);
                break;
            case MetadataCollection.Buildings:
                metadata = this.findMetadata(this.uid, data.buildingAttributes);
                break;
            case MetadataCollection.Rewards:
                metadata = this.findMetadata(this.uid, data.rewards);
                break;
            case MetadataCollection.TechActions:
                metadata = this.findMetadata(this.uid, data.techActionAttributes);
                break;
            case MetadataCollection.TechPassives:
                metadata = this.findMetadata(this.uid, data.techPassiveAttributes);
                break;
        }

        if (metadata === undefined) {
            return false;
        }

        this.change(metadata);
        return true;
    }

    findMetadata(id: string, collection: Metadata[]): Metadata | undefined {
        return collection.find(m => m.uid === id) ?? undefined;
    }
}