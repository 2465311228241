import {CommandBase} from "./commandBase";
import {Metadata, RemoteConstantsModel} from "../models/remoteConstants.model";
import {SetAttributeLocalizationCommand} from "./setAttributeLocalization.command";
import {v4 as uuidv4} from "uuid";

export class AddTechAttributeCommand extends CommandBase {
    id: string;
    action: boolean;

    constructor(id: string, action: boolean) {
        super();
        this.id = id;
        this.action = action;
    }

    execute(data: RemoteConstantsModel): boolean {
        if (this.id === '') {
            return false;
        }

        if (this.action) {
            data.techActionAttributes = this.addToCollection(data, this.id, data.techActionAttributes)
        } else {
            data.techPassiveAttributes = this.addToCollection(data, this.id, data.techPassiveAttributes)
        }

        return true;
    }

    private addToCollection(data: RemoteConstantsModel, attribute: string, attributes: Metadata[]): Metadata[] {
        if (attributes === undefined || attributes === null) {
            attributes = [];
        }

        const uid = uuidv4();
        new SetAttributeLocalizationCommand(uid, this.id).execute(data);

        attributes.push({
            uid: uid,
            id: attribute,
            active: true,
            values: {},
            entities: {}
        });

        return attributes;
    }
}