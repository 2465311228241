import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class RemoveSoundCommand extends CommandBase {
    id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }

    execute(data: RemoteConstantsModel): boolean {
        for (const key of Object.keys(data.sounds)) {
            if (key === this.id) {
                const fileName = data.sounds[key].fileName;
                data.fileArchive.push(fileName);
            }
        }

        delete data.sounds[this.id];
        return true;
    }
}