import {useApp} from "../../app.context";
import StringInputLabel from "../../elements/stringInputLabel.element";
import {useState} from "react";
import {useIntegrator} from "../../integrator.context";

function NotificationView() {
    const {branch, branches, applyBranch} = useIntegrator();
    const {addBranch, removeBranch, setViewBranch} = useApp();
    const [name, setName] = useState<string>('');

    async function createNewBranch() {
        if (name === '' || branches.includes(name)) {
            return;
        }

        await addBranch(name);
    }

    return (
        <div className={'layout vertical overlay'} onClick={() => {
            setViewBranch(false);
        }}>
            <div className={'layout vertical gap box'} style={{padding: 10, gap: 10, width: 400}}
                 onClick={e => e.stopPropagation()}
            >
                <p className={'text header'}>Branches</p>
                <div className={'layout vertical gap'} style={{maxHeight: 200, overflowY: 'auto'}}>
                    {branches.map((b, index) => {
                        const isMain = b === 'Master';
                        const isSelected = b === branch;
                        return (
                            <div key={index}
                                 className={'layout horizontal center stretch label'}
                                 style={{
                                     border: `solid 1px ${isSelected ? 'var(--tint-color)' : 'var(--overlay-color)'}`
                                 }}
                                 onClick={async () => {
                                     await applyBranch(b);
                                 }}
                            >
                                <p>{b}</p>
                                <button style={{opacity: isMain ? 0.3 : 1}} onClick={() => {
                                    if (!isMain) {
                                        removeBranch(b);
                                    }
                                }}>
                                    <span className={'fa-solid fa-trash'}/>
                                </button>
                            </div>
                        )
                    })}
                </div>
                <p className={'text header'}>New Branch</p>
                <StringInputLabel title={'Name'} value={name} placeholder={'Enter name...'} onChange={setName}/>
                <button onClick={async () => createNewBranch()}>Add</button>
            </div>
        </div>
    )
}

export default NotificationView;