import {useState} from "react";
import {useApp} from "../../app.context";
import StringInput from "../../elements/stringInput.element";
import {useIntegrator} from "../../integrator.context";
import {ChangeLocalizationValuesCommand} from "../../commands/changeLocalizationValues.command";
import {RemoveLocalizationValueCommand} from "../../commands/removeLocalizationValue.command";
import {ChangeLocalizationValueCommand} from "../../commands/changeLocalizationValue.command";
import {formatString} from "../../utils/text.utils";

function LocalizationEditorView() {
    const {state, executeCommand} = useIntegrator();
    const {setNotification} = useApp();

    const [newKey, setNewKey] = useState<string>('');

    const languages = Object.keys(state.localization);
    const keys = Object.keys(state.localization[languages[0]]);

    function addNewKey() {
        if (newKey === '') {
            setNotification({
                title: 'Invalid Input',
                message: 'Please enter a valid key.'
            })
            return;
        }

        if (newKey in state.localization['en']) {
            setNotification({
                title: 'Key Already Exists',
                message: 'Please enter a new key.'
            })
            return;
        }

        executeCommand(new ChangeLocalizationValuesCommand(newKey, 'New Value'));
        setNewKey('');
    }

    return (
        <div className={'layout vertical'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout vertical gap'} style={{height: 0, overflowY: 'auto', flexGrow: 1}}>
                {keys.map((key, i) => {
                    const languages = Object.keys(state.localization);
                    return (
                        <div className={'layout horizontal gap'}>
                            <div key={i} className={'layout horizontal'}
                                 style={{color: "white", alignItems: 'center', gap: 10, width: 300}}>
                                <span className={'fa-solid fa-trash'} onClick={() =>
                                    executeCommand(new RemoveLocalizationValueCommand(key))
                                }/>
                                <p key={i}
                                   style={{
                                       padding: '5.75px 0px',
                                       fontSize: 'var(--font-size-default)'
                                   }}
                                >
                                    {formatString(key)}
                                </p>
                            </div>
                            {languages.map((language, index) => {
                                const v = state.localization[language][key];
                                return (
                                    <StringInput key={i} value={v}
                                                 style={{width: 240}}
                                                 onChange={(v) =>
                                                     executeCommand(new ChangeLocalizationValueCommand(keys[i], v, language))
                                                 }/>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
            <input placeholder={'Enter key...'} value={newKey} onChange={v => setNewKey(v.target.value)}/>
            <button onClick={addNewKey}>Add New Key</button>
        </div>
    )
}

export default LocalizationEditorView;