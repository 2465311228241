import CircularTree from "./circularTree";
import {useDrag, useDrop} from "react-dnd";
import {RemoteConstantsModel, TechTreeComponent} from "../../models/remoteConstants.model";
import {useIntegrator} from "../../integrator.context";
import {RemoveTechUnlockCommand} from "../../commands/removeTechUnlock.command";
import EntityLabel from "../../elements/entityLabel";
import MetadataDragLabel from "../../elements/metadataDragLabel";
import {useApp} from "../../app.context";
import NumberInputLabel from "../../elements/numberInputLabel.element";
import {UpdateTechTierPriceCommand} from "../../commands/updateTechTierPrice.command";

function TechTreeEditorView() {
    return (
        <div className={'layout horizontal'} style={{gap: 10, flexGrow: 1}}>
            <div className={'layout center'} style={{position: 'relative', width: '75vw', overflow: 'hidden'}}>
                <CircularTree/>
            </div>
            <div className={'layout vertical flex-grow'} style={{gap: 10}}>
                <PriceList/>
                <AssetsList/>
            </div>
        </div>
    );
}

function PriceList() {
    const {state, executeCommand} = useIntegrator();

    function getTierCount(): number {
        let count = -1;

        let cur: TechTreeComponent | undefined = state.techTree[0];
        while (cur !== undefined) {
            count++;
            // eslint-disable-next-line no-loop-func
            cur = state.techTree.find(t => t.id === cur?.children[0]);
        }

        return count;
    }

    function getTierPrice(tier: number): number {
        const prices = state.techConfig?.prices;
        if (prices === undefined || tier >= prices.length) {
            return 0;
        }

        return prices[tier];
    }

    return (
        <div className={'layout vertical gap box'} style={{gap: 10}}>
            <p className={'text header'}>Prices in Gold</p>
            <div className={'layout vertical gap'}>
                {Array.from({length: getTierCount()}).map((_, index) => {
                    return <NumberInputLabel title={`Tier ${index + 1}`} value={getTierPrice(index)} onChange={v => {
                        executeCommand(new UpdateTechTierPriceCommand(index, v));
                    }}/>
                })}
            </div>
        </div>
    )
}

function AssetsList() {
    const {state, executeCommand} = useIntegrator();

    const [{canDrop}, drop] = useDrop({
        accept: 'entity',
        drop: (item: any) => executeCommand(new RemoveTechUnlockCommand(item.nodeId, item.id)),
        collect: (monitor) => {
            return {
                canDrop: monitor.isOver({shallow: true})
            };
        },
    });

    return (
        <div ref={drop} className={'layout vertical gap box'} style={{
            width: '25vw',
            height: 0,
            flexGrow: 1,
            overflowY: 'auto',
            border: `solid 1px ${canDrop ? 'var(--tint-color)' : 'var(--overlay-color)'}`
        }}>
            <div className={'layout vertical gap'} style={{
                flexGrow: 1,
                overflowY: 'auto'
            }}>
                <p className={'text header'}>{`Units`}</p>
                {state.units.map((unit, i) => {
                    return <EntityDragLabel key={i} id={unit.id}/>
                })}
                <p className={'text header'}>{`Buildings`}</p>
                {state.buildings.map((unit, i) => {
                    return <EntityDragLabel key={i} id={unit.id}/>
                })}
                <p className={'text header'}>{`Actions`}</p>
                {state.techActionAttributes !== undefined && state.techActionAttributes !== null && state.techActionAttributes.map((metadata, index) => {
                    return <MetadataDragLabel key={index} uid={metadata.uid} type={'entity'} draggable={() => !alreadyApplied(state, metadata.uid)}/>
                })}
                <p className={'text header'}>{`Passives`}</p>
                {state.techPassiveAttributes !== undefined && state.techPassiveAttributes !== null && state.techPassiveAttributes.map((metadata, index) => {
                    return <MetadataDragLabel key={index} uid={metadata.uid} type={'entity'} draggable={() => !alreadyApplied(state, metadata.uid)}/>
                })}
            </div>
        </div>
    )
}

function EntityDragLabel(props: {id: string}) {
    const {state} = useIntegrator();
    const {setNotification} = useApp();

    const [{isDragging}, drag] = useDrag({
        type: 'entity',
        item: {
            id: props.id,
        },
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
        canDrag: !alreadyApplied(state, props.id)
    });

    const style = {
        opacity: isDragging || alreadyApplied(state, props.id) ? 0.5 : 1,
    };

    return (
        <div ref={drag} style={style} onClick={() => {
            setNotification({
                title: props.id,
                message: props.id + '.description',
                edit: true
            })
        }}>
            <EntityLabel id={props.id}/>
        </div>
    );
}

function alreadyApplied(remoteConstants: RemoteConstantsModel, unlock: string): boolean {
    return remoteConstants.techTree.some(t =>
        t.unlocks !== undefined && t.unlocks !== null && t.unlocks.some(a => a === unlock));
}

export default TechTreeEditorView;