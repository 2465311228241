import {createContext, useContext, useState} from "react";
import {RemoteConstantsModel} from "./models/remoteConstants.model";
import {CommandBase} from "./commands/commandBase";
import {get} from "./utils/http.client";
import {apiBindings} from "./utils/constants";

interface IntegratorProviderProps {
    children: React.ReactNode;
}

interface IntegratorContextData {
    branches: string[];
    setBranches(value: string[]): void;
    branch: string;
    setBranch(value: string): void;
    applyBranch(value: string): Promise<boolean>;
    state: RemoteConstantsModel;
    forceSetState(state: RemoteConstantsModel): void;
    refreshState(branch: string): Promise<boolean>;
    refreshBranches(): Promise<boolean>;
    executeCommand(command: CommandBase): void;
    goBack(): void;
    goForward(): void;
    canGoBack(): boolean;
    canGoForward(): boolean;
}

interface HistoryData {
    frame: number;
    data: string;
    command: CommandBase | null;
}

const IntegratorContext = createContext<IntegratorContextData>({} as IntegratorContextData);

export function IntegratorProvider(props: IntegratorProviderProps) {
    const [branches, setBranches] = useState<string[]>([]);
    const [branch, setBranch] = useState<string>(getStoredBranch());
    const [frame, setFrame] = useState<number>(0);
    const [state, setState] = useState<RemoteConstantsModel>({} as RemoteConstantsModel);
    const [history, setHistory] = useState<HistoryData[]>([]);
    const [historyIndex, setHistoryIndex] = useState<number>(0);

    function executeCommand(command: CommandBase): void {
        const result = command.execute(state);
        if (!result) {
            return;
        }

        const newFrame = frame + 1;
        setFrame(newFrame);

        if (historyIndex > 0) {
            setHistoryIndex(0);
            history.splice(0, historyIndex);
        }

        history.unshift({
            frame: newFrame,
            data: JSON.stringify(state),
            command: command
        });

        setHistory(history);
        forceSetState(state);
    }

    function goBack() {
        if (!canGoBack()) {
            return;
        }

        setFromHistory(historyIndex + 1);
    }

    function goForward() {
        if (!canGoForward()) {
            return;
        }

        setFromHistory(historyIndex - 1);
    }

    function canGoBack(): boolean {
        return history.length - historyIndex - 1 > 0;
    }

    function canGoForward(): boolean {
        return historyIndex > 0;
    }

    function setFromHistory(index: number) {
        const historyData = history[index];
        setFrame(historyData.frame);
        setHistoryIndex(index);
        setState(JSON.parse(historyData.data));
    }

    async function refreshState(branch: string): Promise<boolean> {
        const dataResult = await get(`${apiBindings.api}getRemoteConstants?branch=${branch}`, undefined);
        if (!dataResult.success) {
            return false;
        }

        const newData = dataResult.data as RemoteConstantsModel;
        setState(newData);

        setFrame(0);
        setHistoryIndex(0);
        setHistory([{
            frame: 0,
            data: JSON.stringify(newData),
            command: null
        }]);

        return true;
    }

    async function refreshBranches(): Promise<boolean> {
        const dataResult = await get(`${apiBindings.api}getBranches`, undefined);
        if (!dataResult.success) {
            return false;
        }

        setBranches(dataResult.data);
        return true;
    }

    function forceSetState(state: RemoteConstantsModel) {
        setState(() => ({
            ...state
        }));
    }

    async function applyBranch(branch: string): Promise<boolean> {
        const result = await refreshState(branch);
        if (!result) {
            return false;
        }

        setBranch(branch);
        storeBranch(branch);
        return true;
    }

    function getStoredBranch(): string {
        return localStorage.getItem('rift-branch') ?? 'Master';
    }

    function storeBranch(branch: string) {
        localStorage.setItem('rift-branch', branch);
    }

    return (
        <IntegratorContext.Provider
            value={{
                branches, setBranches,
                branch, setBranch,
                applyBranch,
                state, forceSetState,
                refreshState,
                refreshBranches,
                executeCommand,
                goBack,
                goForward,
                canGoBack,
                canGoForward,
            }}>
            {props.children}
        </IntegratorContext.Provider>
    )
}

export const useIntegrator = () => useContext(IntegratorContext);