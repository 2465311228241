import {CommandBase} from "./commandBase";
import {RemoteConstantsModel} from "../models/remoteConstants.model";

export class AddSoundCommand extends CommandBase {
    id: string;
    type: string;

    constructor(id: string, type: string) {
        super();
        this.id = id;
        this.type = type;
    }

    execute(data: RemoteConstantsModel): boolean {
        data.sounds ??= {};
        data.sounds[this.id] = {
            fileName: '',
            type: this.type,
            volume: 1,
            channels: 1,
            frequency: 44100
        }

        return true;
    }
}