import StringInput from "./stringInput.element";
import {CSSProperties, HTMLInputTypeAttribute} from "react";
import {Tooltip} from "@mui/material";

interface StringInputLabelProps {
    title: string;
    value: string;
    placeholder?: string;
    tooltip?: string;
    type?: HTMLInputTypeAttribute;
    inputStyle?: CSSProperties;
    onChange(value: string): void;
}

function StringInputLabel(props: StringInputLabelProps) {
    return (
        <div className={'layout horizontal stretch gap'} style={{alignItems: 'center', gap: 10}}>
            <div className={'layout horizontal center gap'}>
                <p>{props.title}</p>
                {props.tooltip && <Tooltip title={props.tooltip} arrow disableInteractive>
                    <span className={'fa-light fa-info-circle'} style={{color: 'white'}}/>
                </Tooltip>}
            </div>
            <StringInput value={props.value} placeholder={props.placeholder} type={props.type}
                         style={props.inputStyle} onChange={props.onChange}/>
        </div>
    )
}

export default StringInputLabel;
